// 代理商管理
const url = '/iparkAdmin/newProduct/'
const urlPriceSet = '/iparkAdmin/productPriceSet/'
const urlPriceInfo = '/iparkAdmin/priceInfo/'
const urlType = '/iparkAdmin/productType/'
const urlFile = '/iparkAdmin/productFile/'
const urlRecord = '/iparkAdmin/productRecord/'
const urlAttr = '/iparkAdmin/productAttribute/'
const urlUnit = '/iparkAdmin/productUnit/'
const urlFileType = '/iparkAdmin/productFileType/'
export interface Apis {
  /** 新增 */
  insertNewProduct: string;
  /** 修改 */
  updateNewProduct: string;
  /** 绑定产品技术型号 */
  updateNewProductModel: string;
  /** 删除 */
  deleteNewProduct: string;
  /** 导入 */
  importNewProduct: string;
  /** 导出预览 */
  previewExcel: string;
  /** 导出下载 */
  downloadExcel: string;
  /** 详情 */
  selectNewProductByProductId: string;
  /** 关联产品列表查询 */
  selectOtherProductList: string;
  /** 分页查询列表 */
  selectNewProductByPage: string;
  /** 不分页查询列表 */
  selectNewProductByList: string;

  /** 产品价格权限 - begin */
  /** 查询用户价格权限列表 */
  selectProductPriceSetByList: string;
  /** 查询还未分配权限的CRM用户列表 */
  selectCrmUserList: string;
  /** 查询用户价格权限 */
  selectProductPriceSetBySetId: string;
  /** 添加用户价格权限 */
  insertProductPriceSet: string;
  /** 修改用户价格权限 */
  updateProductPriceSet: string;
  /** 删除用户价格权限 */
  deleteProductPriceSet: string;
  /** 产品价格权限 - end */

  /** 产品价格 - begin */
  /** 查询当前用户价格权限 */
  selectLoginUserPriceSet: string;
  /** 查询价格列表 */
  selectPriceInfoByPage: string;
  /** 查询最新价格 */
  selectNewPriceInfoList: string;
  /** 添加价格 */
  insertPriceInfo: string;
  /** 修改价格 */
  updatePriceInfo: string;
  /** 删除价格 */
  deletePriceInfo: string;
  /** 查看价格 */
  selectPriceInfoByPriceId: string;
  /** 产品价格- end */

  /** 产品类型管理 - begin */
  /** 新增 */
  insertProductType: string;
  /** 修改 */
  updateProductType: string;
  /** 删除 */
  deleteProductType: string;
  /** 详情 */
  selectProductTypeByProductTypeId: string;
  /** 分页查询列表,不传page及size就是不分页查询 */
  selectProductTypeByPage: string;
  /** 产品类型管理 - end */

  /** 产品文件管理 - begin */
  /** 新增 */
  insertProductFile: string;
  /** 删除 */
  deleteDataProductFile: string;
  /** 查询列表 */
  selectProductFileByList: string;
  /** 产品文件管理 - end */

  /** 产品操作记录管理- begin */
  /** 详情 */
  selectProductRecordById: string;
  /** 分页查询列表 */
  selectProductRecordByPage: string;
  /** 产品操作记录管理 - end */

  /** 产品属性管理 - begin */
  /** 新增 */
  insertProductAttribute: string;
  /** 修改 */
  updateProductAttribute: string;
  /** 删除 */
  deleteProductAttribute: string;
  /** 详情 */
  selectProductAttributeByAttributeId: string;
  /** 排序 */
  updateOrderNum: string;
  /** 分页查询列表,不传page及size就是不分页查询 */
  selectProductAttributeByPage: string;
  /** 产品属性管理 - end */

  /** 产品计量单位管理 - begin */
  /** 新增 */
  insertProductUnit: string;
  /** 修改 */
  updateProductUnit: string;
  /** 删除 */
  deleteProductUnit: string;
  /** 详情 */
  selectProductUnitByUnitId: string;
  /** 分页查询列表,不传page及size就是不分页查询 */
  selectProductUnitByPage: string;
  /** 产品计量单位管理 - end */

  /** 产品文件类型管理 - begin */
  /** 新增 */
  insertProductFileType: string;
  /** 修改 */
  updateProductFileType: string;
  /** 删除 */
  deleteProductFileType: string;
  /** 详情 */
  selectProductFileTypeByTypeId: string;
  /** 分页查询列表,不传page及size就是不分页查询 */
  selectProductFileTypeByPage: string;
  /** 产品文件类型管理 - end */
}

export const apis: Apis = {
  insertNewProduct: url + 'insertNewProduct',
  updateNewProduct: url + 'updateNewProduct',
  updateNewProductModel: url + 'updateNewProductModel',
  deleteNewProduct: url + 'deleteNewProduct',
  importNewProduct: url + 'importNewProduct',
  previewExcel: url + 'previewExcel',
  downloadExcel: url + 'downloadExcel',
  selectNewProductByProductId: url + 'selectNewProductByProductId',
  selectOtherProductList: url + 'selectOtherProductList',
  selectNewProductByPage: url + 'selectNewProductByPage',
  selectNewProductByList: url + 'selectNewProductByList',
  selectProductPriceSetByList: urlPriceSet + 'selectProductPriceSetByList',
  selectCrmUserList: urlPriceSet + 'selectCrmUserList',
  selectProductPriceSetBySetId: urlPriceSet + 'selectProductPriceSetBySetId',
  insertProductPriceSet: urlPriceSet + 'insertProductPriceSet',
  updateProductPriceSet: urlPriceSet + 'updateProductPriceSet',
  deleteProductPriceSet: urlPriceSet + 'deleteProductPriceSet',
  selectLoginUserPriceSet: urlPriceInfo + 'selectLoginUserPriceSet',
  selectPriceInfoByPage: urlPriceInfo + 'selectPriceInfoByPage',
  selectNewPriceInfoList: urlPriceInfo + 'selectNewPriceInfoList',
  insertPriceInfo: urlPriceInfo + 'insertPriceInfo',
  updatePriceInfo: urlPriceInfo + 'updatePriceInfo',
  deletePriceInfo: urlPriceInfo + 'deletePriceInfo',
  selectPriceInfoByPriceId: urlPriceInfo + 'selectPriceInfoByPriceId',
  insertProductType: urlType + 'insertProductType',
  updateProductType: urlType + 'updateProductType',
  deleteProductType: urlType + 'deleteProductType',
  selectProductTypeByProductTypeId: urlType + 'selectProductTypeByProductTypeId',
  selectProductTypeByPage: urlType + 'selectProductTypeByPage',
  insertProductFile: urlFile + 'insertProductFile',
  deleteDataProductFile: urlFile + 'deleteDataProductFile',
  selectProductFileByList: urlFile + 'selectProductFileByList',
  selectProductRecordById: urlRecord + 'selectProductRecordById',
  selectProductRecordByPage: urlRecord + 'selectProductRecordByPage',
  insertProductAttribute: urlAttr + 'insertProductAttribute',
  updateProductAttribute: urlAttr + 'updateProductAttribute',
  deleteProductAttribute: urlAttr + 'deleteProductAttribute',
  selectProductAttributeByAttributeId: urlAttr + 'selectProductAttributeByAttributeId',
  updateOrderNum: urlAttr + 'updateOrderNum',
  selectProductAttributeByPage: urlAttr + 'selectProductAttributeByPage',
  insertProductUnit: urlUnit + 'insertProductUnit',
  updateProductUnit: urlUnit + 'updateProductUnit',
  deleteProductUnit: urlUnit + 'deleteProductUnit',
  selectProductUnitByUnitId: urlUnit + 'selectProductUnitByUnitId',
  selectProductUnitByPage: urlUnit + 'selectProductUnitByPage',
  insertProductFileType: urlFileType + 'insertProductFileType',
  updateProductFileType: urlFileType + 'updateProductFileType',
  deleteProductFileType: urlFileType + 'deleteProductFileType',
  selectProductFileTypeByTypeId: urlFileType + 'selectProductFileTypeByTypeId',
  selectProductFileTypeByPage: urlFileType + 'selectProductFileTypeByPage'
}
