
// 私有化服务
export interface Apis {
  selectPrivatizationByPage: string;
  insertPrivatization: string;
  updatePrivatization: string;
  deletePrivatization: string;
  selectPrivatizationByServiceId: string;
  updatePrivatizationState: string;
  updatePrivatizationNum: string;
}
const url = '/iparkAdmin/privatization/'
export const apis: Apis = {
  // 分页查询私有化服务列表
  selectPrivatizationByPage: url + 'selectPrivatizationByPage',
  // 新增私有化服务
  insertPrivatization: url + 'insertPrivatization',
  // 编辑
  updatePrivatization: url + 'updatePrivatization',
  // 删除
  deletePrivatization: url + 'deletePrivatization',
  // 详情
  selectPrivatizationByServiceId: url + 'selectPrivatizationByServiceId',
  // 启用/禁用私有化服务
  updatePrivatizationState: url + 'updatePrivatizationState',
  // 更新已用数量
  updatePrivatizationNum: url + 'updatePrivatizationNum'
}
