export interface Apis {
  /** 列表 */
  selectDeviceTypeByPage: string;
  /** 详情 */
  selectDeviceTypeById: string;
  /** 编辑 */
  updateDeviceType: string;
}

export const apis: Apis = {
  selectDeviceTypeByPage: '/iparkAdmin/deviceType/selectDeviceTypeByPage',
  selectDeviceTypeById: '/iparkAdmin/deviceType/selectDeviceTypeById',
  updateDeviceType: '/iparkAdmin/deviceType/updateDeviceType'
}
