export interface Apis {
  // 查询资产列表
  selectAssetByPage: string;
  // 查询资产详情
  selectAssetByAssetId: string;
  // 添加资产
  insertAsset: string;
  // 编辑资产经纬度
  updateLocation: string;
  // 删除资产
  deleteAsset: string;
}

export const apis: Apis = {
  selectAssetByPage: '/iparkAdmin/asset/selectAssetByPage',
  selectAssetByAssetId: '/iparkAdmin/asset/selectAssetByAssetId',
  insertAsset: '/iparkAdmin/asset/insertAsset',
  updateLocation: '/iparkAdmin/asset/updateLocation',
  deleteAsset: '/iparkAdmin/asset/deleteAsset'
}
