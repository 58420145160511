import Vue from 'vue'
import VueRouter, { RouteConfig, RawLocation, Route } from 'vue-router'
import { Message } from 'element-ui'
import Layout from '../views/Layout.vue'
import supplier from './supplier'
import device from './device'
import menu from './menu'
import disease from './diseases'
import workGuide from './workGuide'
import insectPests from './insectPests'
import information from './information'
import trainingVideo from './trainingVideo'
import potion from './potion'
import expert from './expert'
import plant from './plant'
import question from './question'
import dictionary from './dictionary'
import categoryManage from './deviceCategory'
import crmmenu from './crmmenu'
import agent from './agent'
import product from './product'
import mechanics from './mechanics'
import assestsManage from './assestsManage'
import experienceApply from './experienceApply'
import privatization from './privatization'
import screen from './screen'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      ...supplier,
      ...device,
      ...menu,
      ...disease,
      ...workGuide,
      ...insectPests,
      ...information,
      ...trainingVideo,
      ...potion,
      ...expert,
      ...plant,
      ...question,
      ...dictionary,
      ...categoryManage,
      ...crmmenu,
      ...agent,
      ...product,
      ...mechanics,
      ...assestsManage,
      ...experienceApply,
      ...privatization,
      ...screen,
      {
        path: '/userList',
        name: 'userList',
        component: () => import('@/views/user/UserList.vue')
      },
      {
        path: '/userList/userAdd',
        name: 'userAdd',
        component: () => import('@/views/user/UserAdd.vue')
      },
      {
        path: '/userList/userUpdate/:id',
        name: 'userUpdate',
        component: () => import('@/views/user/UserAdd.vue')
      },
      {
        path: '/customerList',
        name: 'customerList',
        component: () => import('@/views/customer/CustomerList.vue')
      },
      {
        path: '/customerList/customerAdd',
        name: 'customerAdd',
        component: () => import('@/views/customer/CustomerAdd.vue')
      },
      {
        path: '/customerList/customerUpdate/:id',
        name: 'customerUpdate',
        component: () => import('@/views/customer/CustomerAdd.vue')
      },
      {
        path: '/customerList/customerDetail/:id',
        name: 'customerDetail',
        component: () => import('@/views/customer/CustomerDetail.vue')
      },
      {
        path: '/messageTemplateList',
        name: 'messageTemplateList',
        component: () => import('@/views/message/MessageTemplate.vue')
      },
      {
        path: '/messageList',
        name: 'messageList',
        component: () => import('@/views/message/Message.vue')
      },
      {
        path: '/version',
        name: 'version',
        component: () => import('@/views/version/Version.vue')
      },
      {
        path: '/version/add',
        name: 'versionAdd',
        component: () => import('@/views/version/VersionAdd.vue')
      },
      {
        path: '/version/update/:id',
        name: 'versionUpdate',
        component: () => import('@/views/version/VersionAdd.vue')
      },
      {
        path: '/roleList',
        name: 'roleList',
        component: () => import('@/views/role/RoleList.vue'),
        meta: {
          title: '角色管理'
        }
      },
      {
        path: '/roleList/add:orderNum',
        name: 'roleAdd',
        component: () => import('@/views/role/RoleAdd.vue'),
        meta: {
          title: '角色新增'
        }
      },
      {
        path: '/roleList/update:id',
        name: 'roleUpdate',
        component: () => import('@/views/role/RoleAdd.vue'),
        meta: {
          title: '角色编辑'
        }
      },
      {
        path: '/404',
        component: () => import('@/views/404.vue')
      }
    ]
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  routes
})

// 临时修复路由跳转为当前路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location: RawLocation) {
  return (originalPush.call(this, location) as unknown as Promise<Route>).catch((err: object) => err) as Promise<Route>
}
// 临时修复路由跳转为当前路由报错问题
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function push (location: RawLocation) {
  return (originalReplace.call(this, location) as unknown as Promise<Route>).catch((err: object) => err) as Promise<Route>
}

// 路由错误处理
router.beforeEach((to, from, next) => {
  /** 判断是否需要登录或者是否已经登录,不需要登录或者已经登录正常跳转
   * 路由配置默认都需要登录，除非配置了requireLogin:false;不需要登录
   * 路由配置了匹配不上重定向404，所以这个不需要校验路由是否匹配上，404页面需要登录，所以如果未登录还是会跳转login
   * */
  if ((to.meta !== undefined && to.meta.requireLogin === false) || window.localStorage.getItem('token')) {
    next()
  } else {
    Message.error('您还未登录或者登录已过期,请先登录!')
    // 需要登录且未登录,跳转登录页面
    next('/login')
  }
})
export default router
