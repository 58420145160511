export interface Apis{
  selectInformationList: string;
  selectInformationDetail: string;
  deleteInformation: string;
  updateInformation: string;
  addInformation: string;
}
export const apis: Apis = {
  selectInformationList: '/iparkAdmin/information/selectInformationByPage',
  selectInformationDetail: '/iparkAdmin/information/selectInformationByInformationId',
  deleteInformation: '/iparkAdmin/information/deleteInformation',
  updateInformation: '/iparkAdmin/information/updateInformation',
  addInformation: '/iparkAdmin/information/insertInformation'

}
