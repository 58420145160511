export interface Apis {
  workGuidePage: string;
  workGuideDetail: string;
  workGuideDelete: string;
  workGuideAdd: string;
  workGuideUpdate: string;
}
export const apis: Apis = {
  workGuidePage: '/iparkAdmin/workGuide/selectWorkGuideByPage',
  workGuideDetail: '/iparkAdmin/workGuide/selectWorkGuideByWorkGuideId',
  workGuideDelete: '/iparkAdmin/workGuide/deleteDataWorkGuide',
  workGuideAdd: '/iparkAdmin/workGuide/insertWorkGuide',
  workGuideUpdate: '/iparkAdmin/workGuide/updateWorkGuide'
}
