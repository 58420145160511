import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [{
  path: '/screenList',
  name: 'screenList',
  component: () => import('@/views/screen/List.vue')
},
{
  path: '/screenList/add',
  name: 'screenAdd',
  component: () => import('@/views/screen/Add.vue')
},
{
  path: '/screenList/edit:id',
  name: 'screenEdit',
  component: () => import('@/views/screen/Add.vue')
},
{
  path: '/screenList/detail:id',
  name: 'screenDetail',
  component: () => import('@/views/screen/Detail.vue')
}
]

export default router
