export interface Apis{
  templatePage: string;
  templateDisable: string;
  templateTypePage: string;
  templateTypeDisable: string;
}

export const apis: Apis = {
  // 分页查询模板列表
  templatePage: '/iparkAdmin/template/selectTemplateByPage',
  // 禁用模板
  templateDisable: '/iparkAdmin/template/disableTemplate',
  // 分页查询消息事件列表
  templateTypePage: '/iparkAdmin/templateType/selectTemplateTypeByPage',
  // 禁用启用
  templateTypeDisable: '/iparkAdmin/templateType/disableTemplateType'
}
