import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import router from '@/router'
import { clearObjEmptyPrama } from './formatData'
import { apis } from '@/api'

axios.defaults.baseURL = ''

axios.interceptors.request.use(config => {
  // 登录鉴权
  config.headers.token = window.localStorage.getItem('token') || ''
  // get方法去除对象空元素
  if (config.method === 'get') {
    config.params = clearObjEmptyPrama(config.params)
  }
  return config
}, error => {
  Promise.reject(error)
})

axios.interceptors.response.use(res => {
  // 导出
  if (res.config.responseType === 'blob') {
    const result = res.data
    const head = res.headers['content-disposition']
    let fname = ''
    if (head) {
      try {
        const info: Array<string> = head.split(';')
        info.forEach(item => {
          if (item.indexOf('filename') >= 0) {
            fname = item.split('=')[1]
            const reg = new RegExp('"', 'g')
            fname = fname.replace(reg, '')
            // 终止循环
            throw new Error('break')
          }
        })
      } catch (err) {}
    }
    if (fname !== '') {
      const blob = new Blob([result])
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob) // 创建下载的链接
      downloadElement.href = href
      downloadElement.download = decodeURIComponent(fname) // 下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
      window.URL.revokeObjectURL(href)
    } else {
      Message({ type: 'error', message: '数据异常，请重试' })
    }
  } else if (res.config.url === apis.common.uploadQiniup) {
    return res.data
  } else {
    if (res.status !== 200) {
      Message({ type: 'error', message: res.data.error })
      return Promise.reject(new Error(res.data))
    }
    // 后台约定的对象 => { code: 200, data: {}, message: '' }
    if (Object.prototype.toString.call(res.data) !== '[object Object]') {
      Message({ type: 'error', message: '数据异常，请重试' })
      return Promise.reject(new Error(res.data))
    }

    if (res.data.code === 200) {
      return res.data.data
    } else if (res.data.code === 800000) {
      MessageBox({
        title: '操作提示',
        message: res.data.message,
        showCancelButton: false,
        confirmButtonText: '确定'
      })
      return res.data.data
    } else if (res.data.code === 100000) {
      localStorage.removeItem('token')
      router.replace('/login')
    } else {
      Message({ type: 'error', message: res.data.message })
    }
    return Promise.reject(new Error(res.data.message))
  }
}, error => {
  Message({ message: error.message, type: 'error', duration: 5000 })
  return Promise.reject(error)
})

/**
 * GET请求方法
 * @param {String} url 请求地址
 * @param {json} params 请求参数
 */
export function get<T> (url: string, params?: object) {
  return axios.get<T>(url, { params })
}

export function post<T> (url: string, data?: object) {
  return axios.post<T>(url, data)
}

export function upload () {
  //
}

export function downloadFile (url: string, json: object) {
  return axios({
    url,
    method: 'post',
    data: json,
    withCredentials: true,
    responseType: 'blob'
  })
}

export default {
  get: get,
  post: post,
  downloadFile: downloadFile
}
