export interface Apis {
  // 业务标签列表
  selectTenantProductLabelByList: string;
    // 新增标签
    insertTenantProductLabel: string;
    // 编辑标签
    updateTenantProductLabel: string;
    // 删除标签
    deleteTenantProductLabel: string;
  // 体验产品列表
  selectTenantProductByPage: string;
  // 体验产品详情
  selectDetailByProductId: string;
  // 体验产品编辑
  updateTenantProduct: string;

  // 申请列表
  selectTenantApplyByPage: string;
  // 新增申请
  insertTenantApply: string;
  // 编辑申请
  updateTenantApply: string;
  // 申请详情
  selectTenantApplyByApplyId: string;
  // 审核申请
  auditTenantApply: string;
  // 录入申请
  recordTenantApply: string;
  // 根据地区查询客户经理
  selectSm: string;
  // 客户经理列表
  selectSmByList: string;
    // 新增客户经理
    insertSm: string;
    // 编辑客户经理
    updateSm: string;
    // 删除客户经理
    deleteDataSm: string;
  selectCustTProductByPage: string;
}

export const apis: Apis = {
  selectTenantProductLabelByList: '/iparkAdmin/tenantProductLabel/selectTenantProductLabelByList',
  insertTenantProductLabel: '/iparkAdmin/tenantProductLabel/insertTenantProductLabel',
  updateTenantProductLabel: '/iparkAdmin/tenantProductLabel/updateTenantProductLabel',
  deleteTenantProductLabel: '/iparkAdmin/tenantProductLabel/deleteTenantProductLabel',
  selectTenantProductByPage: '/iparkAdmin/tenantProduct/selectTenantProductByPage',
  selectDetailByProductId: '/iparkAdmin/tenantProduct/selectDetailByProductId',
  updateTenantProduct: '/iparkAdmin/tenantProduct/updateTenantProduct',

  selectTenantApplyByPage: '/iparkAdmin/tenantApply/selectTenantApplyByPage',
  insertTenantApply: '/iparkAdmin/tenantApply/insertTenantApply',
  updateTenantApply: '/iparkAdmin/tenantApply/updateTenantApply',
  selectTenantApplyByApplyId: '/iparkAdmin/tenantApply/selectTenantApplyByApplyId',
  auditTenantApply: '/iparkAdmin/tenantApply/auditTenantApply',
  recordTenantApply: '/iparkAdmin/tenantApply/recordTenantApply',
  selectSmByList: '/iparkAdmin/tenantSm/selectSmByList',
  selectSm: '/iparkAdmin/tenantSm/selectSm',
  insertSm: '/iparkAdmin/tenantSm/insertSm',
  updateSm: '/iparkAdmin/tenantSm/updateSm',
  deleteDataSm: '/iparkAdmin/tenantSm/deleteDataSm',
  selectCustTProductByPage: '/iparkAdmin/tenantProduct/selectCustTProductByPage'
}
