export interface Apis {
  selectQuestionByPage: string; // 提问分页
  selectQuestionByQuestionId: string; // 提问详情
  deleteQuestion: string; // 提问删除
  updateQuestion: string; // 提问更新
  insertQuestion: string; // 提问新增
  selectAnswerByPage: string; // 回答分页
  selectAnswerByAnswerId: string; // 回答详情
  deleteAnswer: string; // 删除回答
  updateAnswer: string; // 修改回答
  addLikeCounts: string;// 点赞回答
  insertAnswer: string; // 新增回答
  updateQuestionIsShow: string; // 切换显示隐藏
  updateAnswerIsTop: string; // 置顶，取消置顶
}

export const apis: Apis = {
  selectQuestionByPage: '/iparkAdmin/question/selectQuestionByPage',
  selectQuestionByQuestionId: '/iparkAdmin/question/selectQuestionByQuestionId',
  deleteQuestion: '/iparkAdmin/question/deleteQuestion',
  updateQuestion: '/iparkAdmin/question/updateQuestion',
  insertQuestion: '/iparkAdmin/question/insertQuestion',
  // 回答
  selectAnswerByPage: '/iparkAdmin/answer/selectAnswerByPage',
  selectAnswerByAnswerId: '/iparkAdmin/answer/selectAnswerByAnswerId',
  deleteAnswer: '/iparkAdmin/answer/deleteAnswer',
  updateAnswer: '/iparkAdmin/answer/updateAnswer',
  addLikeCounts: '/iparkAdmin/answer/addLikeCounts',
  insertAnswer: '/iparkAdmin/answer/insertAnswer',
  updateQuestionIsShow: '/iparkAdmin/question/updateQuestionIsShow',
  updateAnswerIsTop: '/iparkAdmin/answer/updateAnswerIsTop'
}
