export interface Apis{
  templateTypePage: string;
  templateTypeDisable: string;
}

export const apis: Apis = {
  // 分页查询消息事件列表
  templateTypePage: '/iparkAdmin/templateType/selectTemplateTypeByPage',
  // 禁用启用
  templateTypeDisable: '/iparkAdmin/templateType/disableTemplateType'
}
