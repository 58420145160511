// 代理商管理
const url = '/iparkAdmin/customerScreen/'
export interface Apis {
  /** 新增 */
  insertCustomerScreen: string;
  /** 修改 */
  updateCustomerScreen: string;
  /** 删除 */
  deleteDataCustomerScreen: string;
  /** 详情 */
  selectCustomerScreenById: string;
  /** 分页查询列表 */
  selectCustomerScreenByPage: string;
}

export const apis: Apis = {
  insertCustomerScreen: url + 'insertCustomerScreen',
  updateCustomerScreen: url + 'updateCustomerScreen',
  deleteDataCustomerScreen: url + 'deleteDataCustomerScreen',
  selectCustomerScreenById: url + 'selectCustomerScreenById',
  selectCustomerScreenByPage: url + 'selectCustomerScreenByPage'
}
