import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/privatization',
    name: 'privatization',
    component: () => import('@/views/privatization/Index.vue')
  },
  {
    path: '/privatization/add',
    name: 'privatizationAdd',
    component: () => import('@/views/privatization/privatizationAdd.vue')
  },
  {
    path: '/privatization/update:id',
    name: 'privatizationUpdate',
    component: () => import('@/views/privatization/privatizationAdd.vue')
  },
  {
    path: '/privatization/detail:id',
    name: 'privatizationDetail',
    component: () => import('@/views/privatization/privatizationDetail.vue')
  }
]

export default router
