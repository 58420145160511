// 菜单设置
const url = '/iparkAdmin/iparkMenu/'
export interface Apis {
  menuPage: string;
  selectIparkMenuTreeList: string;
  menuAdd: string;
  menuUpdate: string;
  menuDisable: string;
  menuDetails: string;
  menuList: string;
  appPage: string;
  selectIparkMenuAppTreeList: string;
  appAdd: string;
  appUpdate: string;
  appDisable: string;
  appDeteil: string;
  configAdd: string;
  selectTableList: string;
  selectDeviceType: string;
  selectMsgTemplate: string;
  selectJurisdiction: string;
}

export const apis: Apis = {
  // 分页查询
  menuPage: url + 'selectIparkMenuByPage',
  // 菜单树状列表
  selectIparkMenuTreeList: url + 'selectIparkMenuTreeList',
  // 新增
  menuAdd: url + 'insertIparkMenu',
  // 编辑
  menuUpdate: url + 'updateIparkMenu',
  // 禁用/启用
  menuDisable: url + 'disableIparkMenu',
  // 详情
  menuDetails: url + 'selectIparkMenuByMenuId',
  // 菜单列表
  menuList: url + 'selectIparkMenuByList',
  // app 列表
  appPage: '/iparkAdmin/iparkMenuApp/selectIparkMenuAppByPage',
  // app 列表
  selectIparkMenuAppTreeList: '/iparkAdmin/iparkMenuApp/selectIparkMenuAppTreeList',
  // app菜单新增
  appAdd: '/iparkAdmin/iparkMenuApp/insertIparkMenuApp',
  // app 菜单修改
  appUpdate: '/iparkAdmin/iparkMenuApp/updateIparkMenuApp',
  // app 菜单禁用/启用
  appDisable: '/iparkAdmin/iparkMenuApp/disableIparkMenuApp',
  // app菜单详情
  appDeteil: '/iparkAdmin/iparkMenuApp/selectIparkMenuAppByMenuId',
  // 保存配置
  configAdd: '/iparkAdmin/jurisdiction/insertJurisdiction',
  // 数据表列表
  selectTableList: '/iparkAdmin/jurisdiction/selectTableList',
  // 设备类型下拉
  selectDeviceType: '/iparkAdmin/jurisdiction/selectDeviceType',
  // 消息模板下拉
  selectMsgTemplate: '/iparkAdmin/jurisdiction/selectMsgTemplate',
  // 获取菜单初始数据
  selectJurisdiction: '/iparkAdmin/jurisdiction/selectJurisdiction'

}
