export interface Apis {
  diseasesPage: string;
  addDiseases: string;
  updateDiseases: string;
  deleteDiseases: string;
  detailDiseases: string;
  /** 导入 */
  importDiseases: string;
}

export const apis: Apis = {
  diseasesPage: '/iparkAdmin/diseases/selectDiseasesByPage',
  addDiseases: '/iparkAdmin/diseases/insertDiseases',
  updateDiseases: '/iparkAdmin/diseases/updateDiseases',
  deleteDiseases: '/iparkAdmin/diseases/deleteDataDiseases',
  detailDiseases: '/iparkAdmin/diseases/selectDiseasesByDiseasesId',
  importDiseases: '/iparkAdmin/diseases/importDiseases'
}
