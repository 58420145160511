export interface Apis {
  insertSupplier: string;
  listByPage: string;
  getSupplier: string;
  updateSupplier: string;
  deleteSupplier: string;
  list: string;
  productByPage: string;
  insertProduct: string;
  updateProduct: string;
  getProductById: string;
  delProductById: string;
  communicateByPage: string;
  insertCommunication: string;
  updateCommunication: string;
  deleteCommunication: string;
  getCommunicationById: string;
  // 导入供应商
  importSupplier: string;
}

export const apis: Apis = {
  insertSupplier: '/iparkAdmin/supplier/insertSupplier',
  listByPage: '/iparkAdmin/supplier/selectSupplierByPage',
  list: '/iparkAdmin/supplier/selectSupplierByList',
  getSupplier: '/iparkAdmin/supplier/selectSupplierBySupplierId',
  updateSupplier: '/iparkAdmin/supplier/updateSupplier',
  deleteSupplier: '/iparkAdmin/supplier/deleteSupplier',
  importSupplier: '/iparkAdmin/supplier/importSupplier',
  productByPage: '/iparkAdmin/product/selectProductByPage',
  insertProduct: '/iparkAdmin/product/insertProduct',
  updateProduct: '/iparkAdmin/product/updateProduct',
  getProductById: '/iparkAdmin/product/selectProductByProductId',
  delProductById: '/iparkAdmin/product/deleteProduct',
  communicateByPage: '/iparkAdmin/communicate/selectCommunicateByPage',
  insertCommunication: '/iparkAdmin/communicate/insertCommunicate',
  updateCommunication: '/iparkAdmin/communicate/updateCommunicate',
  deleteCommunication: '/iparkAdmin/communicate/deleteCommunicate',
  getCommunicationById: '/iparkAdmin/communicate/selectCommunicateByCommunicateId'
}
