export interface Apis{
  insertMedicine: string;
  updateMedicine: string;
  deleteMedicine: string;
  detailDataMedicine: string;
  selectMedicineList: string;
  /** 导入 */
  importMedicine: string;
  /** 药剂推荐状态更新 */
  updateMedicineRecommendStatus: string;
}
export const apis: Apis = {
  insertMedicine: '/iparkAdmin/medicine/insertMedicine',
  updateMedicine: '/iparkAdmin/medicine/updateMedicine',
  deleteMedicine: '/iparkAdmin/medicine/deleteMedicine',
  detailDataMedicine: '/iparkAdmin/medicine/selectMedicineByMedicineId',
  selectMedicineList: '/iparkAdmin/medicine/selectMedicineByPage',
  importMedicine: '/iparkAdmin/medicine/importMedicine',
  updateMedicineRecommendStatus: '/iparkAdmin/medicine/updateMedicineRecommendStatus'
}
