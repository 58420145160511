export interface Apis {
  selectPestsByPage: string;
  insertPestsAdd: string;
  selectPestsDetail: string;
  updatePests: string;
  deleteDataPests: string;
  /** 导入 */
  importPests: string;
}

export const apis: Apis = {
  selectPestsByPage: '/iparkAdmin/pests/selectPestsByPage',
  insertPestsAdd: '/iparkAdmin/pests/insertPests',
  selectPestsDetail: '/iparkAdmin/pests/selectPestsByPestsId',
  updatePests: '/iparkAdmin/pests/updatePests',
  deleteDataPests: '/iparkAdmin/pests/deleteDataPests',
  importPests: '/iparkAdmin/pests/importPests'
}
