import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/utils/ajax'
import { apis } from '@/api'
// import { UserState } from './modules/user'

Vue.use(Vuex)

export interface RootState {
  keepAlive: string[];
  menuList: Array<object>;
  buttonList: Array<object>;
}

export default new Vuex.Store<RootState>({
  state: {
    keepAlive: [],
    menuList: [],
    buttonList: []
  },
  mutations: {
    addKeepAlive (state: RootState, data: string) {
      if (!state.keepAlive.includes(data)) {
        state.keepAlive.push(data)
      }
    },
    removeKeepAlive (state: RootState, data: string) {
      const index = state.keepAlive.findIndex(item => item === data)
      state.keepAlive.splice(index, 1)
    },
    updateMenuList (state: RootState, data: Array<object>) {
      console.log('menuList:', state.menuList)
      state.menuList = data
    },
    updateButtonList (state: RootState, data: Array<object>) {
      state.buttonList = data
    }
  },
  actions: {
    // 获取菜单
    getMenu ({ commit }) {
      axios.get(apis.crmmenu.menuList).then(res => {
        commit('updateMenuList', res)
        localStorage.setItem('menuList', JSON.stringify(res))
      })
    }
  }
})

// // Declare empty store first, dynamically register all modules later.
// export default new Vuex.Store<RootState>({})
