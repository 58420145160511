export interface Apis {
  plantPage: string;
  plantAdd: string;
  plantUpdate: string;
  plantDetail: string;
  plantDelete: string;
  plantList: string; // 植物不分页列表
  plantBranchList: string; // 纲目科属种查询
  /** 导入 */
  importPlant: string;
}
export const apis: Apis = {
  plantPage: '/iparkAdmin/plant/selectPlantByPage',
  plantAdd: '/iparkAdmin/plant/insertPlant',
  plantUpdate: '/iparkAdmin/plant/updatePlant',
  plantDetail: '/iparkAdmin/plant/selectPlantByPlantId',
  plantDelete: '/iparkAdmin/plant/deletePlant',
  plantList: '/iparkAdmin/plant/selectPlantByList',
  plantBranchList: '/iparkAdmin/plant/selectPlantTypeList',
  importPlant: '/iparkAdmin/plant/importPlant'
}
