// 接口名定义
export interface Apis {
  login: string;
  logout: string;
  getVerifyCode: string;
}

export const apis: Apis = {
  login: '/iparkAdmin/login',
  logout: '/iparkAdmin/logout',
  getVerifyCode: '/iparkAdmin/getVerifyCode'
}
